@use '../utils'as utils;
@use '../variables'as var;
@use "~bootstrap/scss/mixins/breakpoints"as breakpoints;

.table {
  width: 100%;

  thead {
    box-shadow: 0px 5px 10px rgba(#000000, 0.1);
    background-color: transparent;
  }

  tbody {
    border-top-color: #dee2e6 !important;
  }

  thead,
  tbody {
    background: none !important;
  }

  tr.mat-header-row,
  tr.cdk-header-row {
    height: 52px;
  }

  .mat-header-cell,
  .cdk-header-cell {
    white-space: nowrap;
    font-weight: bold;
    font-size: 0.850rem;
    color: rgba(0, 0, 0, 087);
  }

  .mat-cell,
  .mat-header-cell,
  .cdk-cell,
  .cdk-header-cell {
    padding: 0.8rem 1rem;
    vertical-align: middle;
  }

  @include breakpoints.media-breakpoint-down(lg, var.$grid-breakpoints) {
    border: 0;
    background-color: var.$offwhite-color;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    tr {
      border-bottom: 1px solid var.$border-color;
      display: block;
      margin-bottom: .625em;
      background-color: var.$light-color;
      box-shadow: 0px 5px 10px rgba(var.$black-color, 0.06);
    }

    td {
      border-bottom: 1px solid var.$border-color;
      display: block;
      font-size: .8em;
      text-align: right;
      border-color: var.$border-color;
    }

    td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    td:last-child {
      border-bottom: 0;
    }

    @include utils.element('buttons') {
      &::before {
        display: none;
      }

      .btn {
        width: 100%;
      }
    }
  }
}
