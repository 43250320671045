$light-color: #ffffff;
$dark-color: #323232;
$dark-blue: #002B66;
$blue-gradient: linear-gradient(180deg, #004995 0%, #002B66 100%);
$offwhite-color: #f3f3f3;
$grey-color: #DADADA;
$light-grey-color: #F3F5F8;
$link-color: #00C0E8;
$green-color: #20B14D;
$black-color: #000000;
$gray-400: #ced4da !default;
$border-color: #eeeff0;
$danger: #d9534f;

