@use 'themes' as *;
@use '@nebular/theme/styles/globals'as *;
@use '@angular/material'as mat;

@import "./assets/styles/variables";

@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/bootstrap-utilities";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/pagination";

@import "./assets/styles/components";


@include nb-install() {
  @include nb-theme-global();
}

@include mat.core();


$shelter-theme: mat.define-light-theme((
 color: (
   primary: mat.define-palette(mat.$indigo-palette, 500),
   accent: mat.define-palette(mat.$pink-palette, A200, A100, A400),
 )
));

@include mat.core-theme($shelter-theme);
@include mat.select-theme($shelter-theme);
@include mat.table-theme($shelter-theme);
@include mat.sort-theme($shelter-theme);
@include mat.paginator-theme($shelter-theme);
@include mat.menu-theme($shelter-theme);
@include mat.dialog-theme($shelter-theme);
@include mat.button-theme($shelter-theme);
@include mat.button-toggle-theme($shelter-theme);
@include mat.tooltip-theme($shelter-theme);

// Include the theme mixins for other components you use here.
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Lato, "Helvetica Neue", sans-serif;
}


.ng-submitted {
  .ng-invalid:not(.ng-invalid-ignore) {
    border-color: nb-theme(color-danger-600) !important;
    color: nb-theme(color-danger-600) !important;
  }
}

.ng-submitted {

  input.ng-invalid:not(.ng-invalid-ignore),
  nz-select.ng-invalid:not(.ng-invalid-ignore) .ant-select-selection {
    border-color: nb-theme(color-danger-600) !important;
  }
}

.ng-submitted {
  .ng-invalid:not(.ng-invalid-ignore) {
    border-color: nb-theme(color-danger-600) !important;
    color: nb-theme(color-danger-600) !important;

    .select-button {
      border-color: nb-theme(color-danger-600) !important;
    }

    h6,
    label {
      color: nb-theme(color-danger-600) !important;
    }

    .text-editor,
    input,
    select,
    nz-input-group,
    nz-input-group {
      border-color: nb-theme(color-danger-600) !important;
      color: nb-theme(color-danger-600) !important;
    }
  }
}


nb-layout .layout .layout-container nb-sidebar.fixed,
nb-layout .layout .layout-container nb-sidebar .main-container-fixed {
  top: 0;
}

nb-sidebar {
  text-transform: uppercase;

  nb-menu {
    .menu-item {
      border-bottom: none;
    }
  }
}


.width {
  &--230 {
    width: 230px !important;
  }
}

.text-blue {
  color: #004995;
}
.bg-blue {
  background-color: #004995;
}

.svg-blue {
  background-color: #004995;
  color: #fff !important;

  svg {
    g {
      fill: #fff
    }
  }
}

.ngx-slider .ngx-slider-bubble.ngx-slider-limit{
  opacity: 1 !important;
}
.ngx-slider .ngx-slider-pointer {
  width: 25px !important;
  height: 25px !important;
  top: -11px !important;
  background-color: #004995!important;
}
.ngx-slider .ngx-slider-pointer:after {
  width: 7px !important;
  height: 7px !important;
  top: 9px !important;
  left: 9px !important;
}
.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
  background-color: #fff !important;
}
