@font-face {
	font-family:'NouvelR';
	src:	url('/assets/fonts/NouvelR-Light.woff2') format('woff2'),
			url('/assets/fonts/NouvelR-Light.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family:'NouvelR';
	src:	url('/assets/fonts/NouvelR-Book.woff2') format('woff2'),
			url('/assets/fonts/NouvelR-Book.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family:'NouvelR';
	src:	url('/assets/fonts/NouvelR-Regular.woff2') format('woff2'),
			url('/assets/fonts/NouvelR-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family:'NouvelR';
	src:	url('/assets/fonts/NouvelR-Semibold.woff2') format('woff2'),
			url('/assets/fonts/NouvelR-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family:'NouvelR';
	src:	url('/assets/fonts/NouvelR-Bold.woff2') format('woff2'),
			url('/assets/fonts/NouvelR-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family:'NouvelR';
	src:	url('/assets/fonts/NouvelR-Extrabold.woff2') format('woff2'),
			url('/assets/fonts/NouvelR-Extrabold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family:'NouvelR-Variable';
	src:	url('/assets/fonts/NouvelR-Variable.woff2') format('woff2'),
			url('/assets/fonts/NouvelR-Variable.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

